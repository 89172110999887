export const address = {
  namespaced: true,
  state: {
    defaultOutlet: 'KFC-Virtual',
    orderType: 1,
    outlet_name: '',
    deliveryAddress: {
      addr_orts: '',
      addr_orts_code: '',
      addr_haalga: '',
      addr_note: '',
      bairname: '',
      bairname2: '',
      bairnote: '',
      full_address: '',
      longitude: '',
      latitude: '',
      podcode: '',
      podph: '',
      podkfc: '',
      podtoktok: '',
    },
    // pickupAddress: '',
  },
  actions: {
    updateOrderType(context, type) {
      context.commit('UPDATE_ORDER_TYPE', type);
    },
    updateOutletName(context, name) {
      context.commit('UPDATE_OUTLET_NAME', name);
    },
    updateDeliveryAddress(context, address) {
      context.commit('UPDATE_DELIVERY_ADDRESS', address);
    },
    // updateDeliveryAddressOrtsHaalga(context, address) {
    //   context.commit('UPDATE_DELIVERY_ADDRESS_ORTS_HAALGA', address);
    // },
    updatePickupAddress(context, address) {
      context.commit('UPDATE_PICKUP_ADDRESS', address);
    },
    deleteDeliveryAddress(context) {
      context.commit('DELETE_DELIVERY_ADDRESS');
    },
    deletePickupAddress(context) {
      context.commit('DELETE_PICKUP_ADDRESS');
    },
  },
  mutations: {
    UPDATE_ORDER_TYPE(state, type) {
      state.orderType = type;
    },
    UPDATE_OUTLET_NAME(state, name) {
      state.outlet_name = name;
    },
    UPDATE_DELIVERY_ADDRESS(state, address) {
      if (!address || address === '') {
        // state.deliveryAddress.full_address = '';
        // state.deliveryAddress.bairname = '';
        // state.deliveryAddress.latitude = 0;
        // state.deliveryAddress.longitude = 0;
        // state.deliveryAddress.podKfc = '';
      } else {
        state.deliveryAddress = address;
        
      }
    },
    UPDATE_PICKUP_ADDRESS(state, address) {
      state.outlet_name = address;
    },
    DELETE_DELIVERY_ADDRESS(state) {
      // state.deliveryAddress = {};
      state.deliveryAddress.full_address = '';
      state.deliveryAddress.bairname = '';
      state.deliveryAddress.bairnote = '';
      state.deliveryAddress.latitude = '';
      state.deliveryAddress.longitude = '';
      state.deliveryAddress.addr_orts = '';
      state.deliveryAddress.addr_orts_code = '';
      state.deliveryAddress.addr_haalga = '';
      state.deliveryAddress.addr_note = '';
      state.deliveryAddress.podKfc = '';

    },
    DELETE_PICKUP_ADDRESS(state) {
      state.outlet_name = state.defaultOutlet;
    },
  },
  getters: {
    getDefaultOutlet(state) {
      return state.defaultOutlet;
    },
    getOrderType(state) {
      return state.orderType;
    },
    getOutletName(state) {
      return state.outlet_name;
    },
    getDeliveryAddress(state) {
      return state.deliveryAddress;
    },
    getPickupAddress(state) {
      return state.outlet_name;
    },
    hasDeliveryAddress(state) {
      return state.deliveryAddress.full_address.length === 0 ? false : true;
    },
    hasPickupAddress(state) {
      if (
        state.outlet_name === state.defaultOutlet ||
        state.outlet_name.length === 0
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
};
