<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="header_wrap">
    <div class="header_top">
      <!-- <router-link to="/" rel="noopener noreferrer"> -->
      <img @click="handleClick('/')" src="@/assets/images/logo_header.png" alt="logo" />
      <!-- </router-link> -->
      <div class="right">
        <!-- <router-link to="/basket"> -->
        <div @click="handleClick('/basket')" class="basket">
          <span>{{ $t('basket') }}</span>
          <basket-icon />
        </div>
        <!-- </router-link> -->

        <div class="burger_menu" @click="burgerToggleMode">
          <img :src="burgerToggle == true
            ? require('@/assets/images/menu-close.svg')
            : require('@/assets/images/menu.svg')
            " alt="icon" />
        </div>
      </div>
    </div>
    <div class="header_bottom" v-if="burgerToggle">
      <div class="top">
        <div id="kfc_login_but" v-if="getUser.name === '' && getUser.phone === ''" class="__kfc_r_8 login"
          @click="loginPopup">
          <img src="@/assets/images/user.png" alt="user" />
          <span>{{ $t('login') }}</span>
        </div>
        <div v-else-if="getUser !== null" class="__kfc_r_8 login" @click="toggleProfileMode">
          <img src="@/assets/images/user.png" alt="" />
          <span>{{ getUser.phone }}</span>
        </div>
        <div class="lang-wrap">
          <span @click="changeLang" :class="locale === 'mn' ? 'active' : ''">Mongolia</span>
          <span @click="changeLang" :class="locale === 'en' ? 'active' : ''">English</span>
        </div>
        <div class="menu-wrap">
          <!-- <router-link to="/"> -->
          <div @click="handleClick('/')" class="item">{{ $t('menu') }}</div>
          <!-- </router-link> -->
          <!-- <router-link to="/outlets"> -->
          <div @click="handleClick('/outlets')" class="item">{{ $t('branch') }}</div>
          <!-- </router-link> -->
        </div>
        <!-- <router-link to="/search-order"> -->
        <div @click="handleClick('/search-order')" class="order-wrap">
          <img src="@/assets/icons/orderCar.png" alt="" />
          <span> {{ $t("trackOrder") }} </span>
        </div>
        <!-- </router-link> -->

      </div>
      <div class="bottom">
        <img src="@/assets/images/logo_full.png" alt="logo" />
      </div>
    </div>
  </div>
  <Login v-if="login" @handleClose="loginPopup" />
</template>

<script>
import Login from "@/components/Popup/Login.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import BasketIcon from './BasketIcon.vue';

export default {
  components: {
    Login,
    BasketIcon,
  },
  props: {
    toggleProfileMode: Function,
  },
  setup () {
    const router = useRouter()
    const login = ref(false);
    const store = useStore();
    const cartCount = computed(() => store.getters['cart/getCartCount']);
    const getUser = computed(() => store.getters['user/getUser']);
    const locale = computed(() => store.getters.locale);
    const i18n = useI18n();
    const loginPopup = () => {
      login.value = !login.value;
    };
    const burgerToggle = ref(false);
    const burgerToggleMode = () => {
      burgerToggle.value = !burgerToggle.value;
    };
    const changeLang = () => {
      if (locale.value === 'en') {
        i18n.locale.value = 'mn'
        store.dispatch('setLocale', 'mn')
      }
      else {
        i18n.locale.value = 'en'
        store.dispatch('setLocale', 'en')
      }
    }
    const handleClick = (url) => {
      router.push(url);
      burgerToggle.value = false;
    }
    return {
      burgerToggle,
      login,
      cartCount,
      locale,
      getUser,
      burgerToggleMode,
      loginPopup,
      changeLang,
      handleClick
    };
  },
};
</script>
<style lang="scss" scoped>
.header_wrap {
  width: 100%;

  .header_top {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 30px;

    a {
      img {
        width: 66px;
        height: auto;
      }
    }

    .right {
      display: flex;
      gap: 30px;
      align-items: center;

      .basket {
        display: flex;
        gap: 15px;
        align-items: center;
        margin: 10px 0;

        span {
          color: $darkMain;
          text-align: center;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          /* 128.571% */
          letter-spacing: -0.3px;
        }

        .basket_count {
          position: relative;

          img {
            width: 30px;
            height: 30px;
          }

          .count {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $darkMain;
            font-size: 14px;
            font-weight: 600;
            line-height: 18px;
            /* 128.571% */
            letter-spacing: -0.3px;
          }
        }
      }

      .burger_menu {
        img {
          cursor: pointer;
        }
      }
    }
  }

  .header_bottom {
    width: 100%;
    height: calc(100vh - 50px);
    padding: 40px 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid $dark12;

    .top {
      display: flex;
      flex-direction: column;
      gap: 16px;

      .login {
        display: flex;
        align-items: center;
        gap: 12px;
        width: 100%;
        cursor: pointer;

        img {
          width: 20px;
          height: 20px;
          object-fit: contain;
        }

        span {
          color: $darkMain;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
          /* 128.571% */
          letter-spacing: -0.3px;
        }
      }

      .lang-wrap {
        display: flex;
        justify-content: space-between;
        border-radius: 4px;
        background: $dark3;
        padding: 12px 22px;
        margin-top: 10px;
        cursor: pointer;

        span {
          color: $dark90;
          font-size: 15px;
          font-weight: 600;
          line-height: 18px;
          /* 120% */
          letter-spacing: -0.3px;
        }

        .active {
          color: $redMain;
        }
      }

      .menu-wrap {
        border-radius: 4px;
        padding: 24px 22px;
        background: $dark3;
        display: flex;
        flex-direction: column;
        gap: 36px;

        .item {
          color: $darkMain;
          font-size: 16px;
          font-weight: 600;
          line-height: 22px;
          /* 137.5% */
          letter-spacing: -0.3px;
          cursor: pointer;
        }
      }

      .order-wrap {
        border-radius: 4px;
        padding: 12px 22px;
        background: $dark3;
        display: flex;
        align-items: center;
        gap: 12px;
        cursor: pointer;
        img {
          width: 24px;
          height: 100%;
        }
        span {
          color: $dark40;
          font-size: 13px;
          font-weight: 500;
          line-height: 16px;
          /* 123.077% */
          letter-spacing: -0.3px;
        }
      }
    }

    .bottom {
      width: 100%;

      img {
        width: 100%;
        height: 110px;
        object-fit: contain;
      }
    }
  }
}
</style>
