<template>
  <Header :toggleProfileMode="toggleProfileMode" />
  <router-view :key="$route.fullPath" />
  <Footer />
  <div id="profileWrap" class="profile-wrap" v-if="toggleProfile">
    <Profile :toggleProfileMode="toggleProfileMode" :toggleProfile="toggleProfile" />
  </div>
  <loader v-if="isLoading" />
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Profile from "@/components/Profile/index.vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { computed, inject, onMounted, ref } from "vue";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import Loader from "./components/Loader.vue";
import Req from "./utils/Request";
import { useStore } from "vuex";

export default {
  name: "App",
  components: {
    Header,
    Footer,
    Profile,
    Swiper,
    SwiperSlide,
    Loader
  },
  setup () {
    const notify = inject('notify');
    const store = useStore();
    const toggleProfile = ref(false);
    const isLoading = ref(false);
    const default_outlet = computed(() => store.getters['address/getDefaultOutlet'])
    const count = ref(0);
    const locale = computed(() => store.getters.locale)
    const toggleProfileMode = () => {
      // itself.value.classList.add('unfade')
      // setTimeout(() => {
      //   emit('handleClose')

      // }, 300);

      toggleProfile.value = !toggleProfile.value;
    };

    const root = document.querySelector('#app')
    root.addEventListener('startloader', () => {
      isLoading.value = true
    })
    root.addEventListener('endloader', () => {
      isLoading.value = false
    })
    root.addEventListener('toastmessage', ({ detail }) => {
      if (locale.value === 'en') {
        switch (detail.message) {
          case 'Амжилттай':
            detail.message = 'Success'
            break;
          case 'Таны утасны дугаар эсвэл баталгаажуулах код буруу байна!':
            detail.message = 'Incorrect confirmation code'
            break;
          case 'Таны нууц үг буруу байна!':
            detail.message = 'Incorrect password'
            break;
          case 'Таны бүртгэл олдсонгүй, Бүртгэлээ дахин шалгана уу!':
            detail.message = 'Your phone number not found, please check your phone number again'
            break;
          case 'Таны утасны дугаарт код аль хэдийн илгээсэн байна!':
            detail.message = 'Code already sent to your phone number!'
            break;
          case 'Таны утасны дугаар бүртгэлтэй байна!':
            detail.message = 'Your phone number registered!'
            break;
          case 'Нууц үг болон баталгаажуулах нууц үг таарахгүй байна!':
            detail.message = 'Password and verification password not match!'
            break;
          default:
            break;
        }
      }
      notify(detail)
    })
    store.dispatch('address/updateOutletName', default_outlet.value)
    return {
      toggleProfile,
      count,
      toggleProfileMode,
      modules: [Navigation, Autoplay],
      isLoading
    };
  },
};
</script>

<style lang="scss" scoped>
.profile-wrap {
  position: fixed;
  z-index: 10;
  background: linear-gradient(269deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}
</style>
