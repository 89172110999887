<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div class="__kfc_popup_right">
        <div class="top">
            <div class="arrow" @click="toggleOrdersMode">
                <img src="@/assets/images/arrow-forward.svg" alt="arrow" />
            </div>
            <div class="wrapper">
                <div class="title">{{ $t('myOrders.title') }}</div>
                <div class="orders">
                    <div class="orders-wrap __kfc_flex __kfc_flex_column">
                        <div class="order" v-for="(order, index) in orders" :key="index">
                            <div class="__kfc_flex __kfc_flex_column order-wrap">
                                <div class="order_item time">
                                    <span class="__kfc_r_9">{{ $t('myOrders.confirmedTime') }}</span>
                                    <span class="__kfc_r_9">{{ formatDateFull(order.confirmed_at) }}</span>
                                </div>
                                <div class="order_item order_type">
                                    <span class="__kfc_r_9">{{ $t('myOrders.orderType') }}</span>
                                    <span v-if="parseInt(order.order_type) === 1" class="__kfc_r_9">{{ $t('delivery') }}</span>
                                    <span v-if="parseInt(order.order_type) === 2" class="__kfc_r_9">{{ $t('pickup') }}</span>
                                </div>
                                <div class="order_item total">
                                    <span class="__kfc_r_9">{{ $t('myOrders.price') }}</span>
                                    <span class="__kfc_r_9 ">{{ $formatCurrency(order.pay_total) }}</span>
                                </div>
                                <button @click="showOrder(order.order_number)"
                                    class="__kfc_button __kfc_button_red order_button">
                                    {{ $t('myOrders.trackOrder') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<style lang="scss" scoped>
.__kfc_popup_right {
    .top {
        display: flex;
        flex-direction: column;
        gap: 30px;
        height: 100%;

        .wrapper {
            display: flex;
            flex-direction: column;
            gap: 40px;
            height: 90%;

            .title {
                color: $darkMain;
                font-family: Oswald;
                font-size: 24px;
                font-weight: 500;
                line-height: 30px;
                /* 125% */
                text-transform: uppercase;
            }


        }
    }

    .orders {
        width: 100%;
        height: 100%;
        overflow-y: auto;

        .orders-wrap {
            gap: 32px;
        }

        .order {
            gap: 6px;
            background: $dark5;
            border-radius: 4px;

            .order-wrap {
                padding: 15px;
                gap: 6px;

                .order_item {
                    display: flex;
                    // gap: 10px;
                    justify-content: space-between;
                }

                .order_button {
                    padding: 6px 10px;
                    align-self: end;
                    margin-top: 10px;
                }
            }


        }
    }

    .bottom {
        padding: 0 40px;

        button {
            width: 100%;
            border: none;
            background: $redMain;
            color: $whiteMain;
            border-radius: 4px;
            padding: 16px 30px;
        }
    }
}
</style>
  
<script>
import Req from "@/utils/Request";
import { formatDateFull } from "@/utils/date";
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
export default {
    props: ['toggleOrdersMode', 'toggleOrders', 'toggleProfileMode'],
    setup(props) {
        const { toggleOrdersMode, toggleOrders, toggleProfileMode } = props
        const store = useStore();
        const router = useRouter()
        const getUser = computed(() => store.getters['user/getUser']);
        const locale = computed(() => store.getters['locale']);
        const refForm = ref(null);
        const orders = ref([]);
        const getMyOrders = async () => {
            const { data: getMyOrders } = await Req({ url: '/manager/getMyOrders', hideSuccess: true, jwtToken: getUser.value.access_token })
            if (getMyOrders.success) {
                orders.value = getMyOrders.result;
            }
        }
        const showOrder = (id) => {
            toggleProfileMode();
            router.push({
                name: 'searchOrder',
                query: {
                    id
                },
            })
        }
        getMyOrders();
        return {
            orders, locale, refForm, formatDateFull, showOrder, toggleOrdersMode, toggleOrders
        }
    }
};
</script>
  