export const cart = {
  namespaced: true,
  state: {
    inCart: [],
    cartVersion: 0,
  },
  actions: {
    // addToCart(context, invId) {
    //   context.commit('ADD_TO_CART', invId);
    // },
    addToCartComboOrMenu(context, item) {
      context.commit('ADD_TO_CART_COMBO_OR_MENU', item);
    },
    updateToCartComboOrMenu(context, item) {
      context.commit('UPDATE_TO_CART_COMBO_OR_MENU', item);
    },
    // addToCartSingle(context, invId) {
    //   context.commit('ADD_TO_CART_SINGLE', invId);
    // },
    addCount(context, index) {
      context.commit('ADD_COUNT', index);
    },
    minusCount(context, index) {
      context.commit('MINUS_COUNT', index);
    },
    removeAddFromCart(context, index) {
      context.commit('REMOVE_ADD_FROM_CART', index);
    },
    removeFromCart(context, index) {
      context.commit('REMOVE_FROM_CART', index);
    },
    clearCart(context) {
      context.commit('CLEAR_CART');
    },
    // changeOrderTotal (context, index, total) {
    //   context.commit('CHANGE_ORDER_TOTAL', index, total)
    // }
  },
  mutations: {
    // ADD_TO_CART(state, item) {
    //   state.cartVersion++;
    //   if (
    //     state.inCart.find(
    //       (x) =>
    //         x.pkey === item.pkey &&
    //         x.parent === item.parent &&
    //         x.type === 'addon'
    //     )
    //   ) {
    //     const result = state.inCart.find(
    //       (x) => x.pkey === item.pkey && x.parent === item.parent
    //     );
    //     const index = state.inCart.findIndex(
    //       (x) => x.pkey === item.pkey && x.parent === item.parent
    //     );
    //     result.count += item.count;
    //     // result.price += item.price
    //     state.inCart[index] = JSON.parse(JSON.stringify(result));
    //   } else {
    //     state.inCart.push(item);
    //   }
    //   // window.sessionStorage.setItem('cart', JSON.stringify(state.inCart))
    // },
    // ADD_TO_CART_SINGLE(state, item) {
    //   state.cartVersion++;
    //   if (state.inCart.find((x) => x.pkey === item.pkey && x.parent === 0)) {
    //     const index = state.inCart.findIndex(
    //       (x) => x.pkey === item.pkey && x.parent === 0
    //     );
    //     state.inCart[index].count += parseInt(item.count);
    //   }
    //   // window.sessionStorage.setItem('cart', JSON.stringify(state.inCart))
    // },
    ADD_TO_CART_COMBO_OR_MENU(state, item) {
      state.cartVersion++;
      for (var i = 0; i < item.length; i++) {
        state.inCart.push(item[i]);
      }
    },
    UPDATE_TO_CART_COMBO_OR_MENU(state, items) {
      const { index } = items.find((itm) => itm.parent === 0);
      state.inCart = state.inCart.filter((item) => index !== item.index);
      state.inCart = state.inCart.filter((item) => index !== item.parent);
      items.forEach((item) => {
        state.inCart.push(item);
      });
    },
    ADD_COUNT(state, index) {
      state.cartVersion++;
      const cartParent = state.inCart[index];
      const items = state.inCart.filter(
        (item) => cartParent.index === item.parent && item.type === 'item'
      );
      // cartParent.count++
      // state.inCart[index] = JSON.parse(JSON.stringify(cartParent))
      state.inCart[index].count++;
    },
    MINUS_COUNT(state, index) {
      state.cartVersion++;
      // let cartParent = state.inCart[index]
      // cartParent.count--
      // state.inCart[index] = JSON.parse(JSON.stringify(cartParent))
      state.inCart[index].count--;
    },
    REMOVE_ADD_FROM_CART(state, index) {
      state.cartVersion++;
      let indexinmenu = state.inCart.indexOf(index);
      state.inCart.splice(indexinmenu, 1);
    },
    REMOVE_FROM_CART(state, index) {
      state.cartVersion++;
      let menu = state.inCart[index];
      state.inCart.splice(index, 1);
      state.inCart = state.inCart.filter((item) => menu.index !== item.parent);
    },
    CLEAR_CART(state) {
      state.cartVersion++;
      state.inCart = [];
    },
  },
  getters: {
    inCart: (state) => {
      return state.inCart.sort((a, b) => a.index - b.index);
    },
    getCartCount: function (state) {
      var sum = 0;
      let cartParents = state.inCart.filter((x) => x.parent === 0);
      cartParents.forEach((element) => {
        sum = sum + element.count;
      });
      return sum;
      // return cartParents.length
    },
    getItems: function (state, parent) {
      return function (parent) {
        return state.inCart.filter(
          (item) => item.parent === parent && item.type === 'item'
        );
      };
    },
    // getAddons: function (state, parent) {
    //   return function (parent) {
    //     return state.inCart.filter(
    //       (item) => item.parent === parent && item.type === 'addon'
    //     );
    //   };
    // },
    // getMenuTotal: function (state) {
    //   let parents = state.inCart.filter((x) => x.parent === 0);
    //   let menuTotal = 0;
    //   for (var p = 0; p < parents.length; p++) {
    //     let addons = state.inCart.filter(
    //       (x) => x.parent === parents[p].index && x.type === 'addon'
    //     );
    //     for (var a = 0; a < addons.length; a++) {
    //       menuTotal += addons[a].price * parents[p].count;
    //     }
    //     menuTotal += parents[p].price * parents[p].count;
    //   }
    //   return menuTotal;
    // },

    getProcessedCart: function (state) {
      const processedCartItems = JSON.parse(JSON.stringify(state.inCart));
      processedCartItems.forEach((cartItem) => {
        if (cartItem.type == 'combo' || cartItem.type == 'menu') {
          delete cartItem.menu;
        } else if (cartItem.type == 'item') {
          const parent = processedCartItems.find(
            (item) => item.index === cartItem.parent
          );
          cartItem.count = cartItem.count * parent.count;
        }
      });
      return processedCartItems;
    },
    getParentMenus: function (state) {
      return state.inCart.filter((x) => x.parent === 0);
    },

    getCartVersion: function (state) {
      return state.cartVersion;
    },
  },
};
