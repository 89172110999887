let userInfo = window.localStorage.getItem('user');
let access_token = window.sessionStorage.getItem('access_token');

export const user = {
  namespaced: true,
  state: {
    user: {
      id: userInfo ? JSON.parse(userInfo).id : '',
      name: userInfo ? JSON.parse(userInfo).name : '',
      phone: userInfo ? JSON.parse(userInfo).phone : '',
      email: userInfo ? JSON.parse(userInfo).email : '',
      access_token: userInfo ? access_token : '',
    },
    address: '',
  },
  actions: {
    setAddr(state, address) {
      state.commit('setAddress', address);
    },
    setUser(state, user) {
      state.commit('setUser', user);
    },
    updateUser(state, data) {
      state.commit('updateUser', data);
    },
    deleteUser(state) {
      state.commit('deleteUser');
    },
  },
  mutations: {
    setAddress(state, address) {
      state.address = address;
    },
    updateUser(state, data) {
      Object.keys(data).forEach((key) => {
        state.user[key] = data[key];
      });
      window.localStorage.setItem('user', JSON.stringify(state.user));
    },
    setUser(state, user) {
      state.user.id = user.id;
      state.user.name = user.name;
      state.user.phone = user.phone;
      state.user.email = user.email;
      state.user.access_token = user.access_token;
      window.localStorage.setItem('user', JSON.stringify(state.user));
      window.sessionStorage.setItem('access_token', user.access_token);
    },
    deleteUser(state) {
      state.user.id = '';
      state.user.name = '';
      state.user.phone = '';
      state.user.email = '';
      state.address = '';
      window.localStorage.removeItem('user');
      window.sessionStorage.removeItem('access_token');
    },
  },
  getters: {
    getAddr(state) {
      return state.address;
    },
    getUser(state) {
      return state.user;
    },
  },
};
