export const menu = {
  namespaced: true,
  state: {
    defaultMenusDelivery: [],
    defaultMenusPickup: [],
    menus: [],
    menuWithCat: [],
    cats: [],
    allCats: [],
    outlets : []
  },
  actions: {
    setDefaultMenuDelivery(context, menu) {
      context.commit('UPDATE_DEFAULT_MENU_DELIVERY', menu);
    },
    setDefaultMenuPickup(context, menu) {
      context.commit('UPDATE_DEFAULT_MENU_PICKUP', menu);
    },
    setMenu(context, menu) {
      context.commit('UPDATE_MENU', menu);
    },
    setCategory(context, cats) {
      context.commit('UPDATE_CATS', cats);
    },
    setAllCategory(context, cats) {
      context.commit('UPDATE_ALL_CATS', cats);
    },
    setOutlets(context, outlets) {
      context.commit('UPDATE_OUTLETS', outlets);
    },
  },
  mutations: {
    UPDATE_OUTLETS(state, outlets) {
      state.outlets = outlets;
    },
    UPDATE_DEFAULT_MENU_DELIVERY(state, menu) {
      state.defaultMenusDelivery = menu.menus;
    },
    UPDATE_DEFAULT_MENU_PICKUP(state, menu) {
      state.defaultMenusPickup = menu.menus;
    },
    UPDATE_MENU(state, menu) {
      state.menus = menu.menus;
      state.menuWithCat = menu.menuWithCat;
    },
    UPDATE_CATS(state, cats) {
      state.cats = cats;
    },
    UPDATE_ALL_CATS(state, cats) {
      state.allCats = cats;
    },
  },
  getters: {
    getDefaultMenusDelivery(state) {
      return state.defaultMenusDelivery;
    },
    getDefaultMenusPickup(state) {
      return state.defaultMenusPickup;
    },
    getMenus(state) {
      return state.menus;
    },
    getMenusWuthCat(state) {
      return state.menuWithCat;
    },
    getCats(state) {
      return state.cats;
    },
    getAllCats(state) {
      return state.allCats;
    },
    getOutlets(state) {
      return state.outlets;
    }
  },
};
